import mapVerify from '@/utils/formValidate'

const getFormatter = (data, value) => {
  return data?.find(t => t.value === value)?.label ?? value
}
// 获取{{ $t('lang.gles.common.moreQuery') }}字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 货位编码
    {
      name: 'goodsPositionCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.goodsPositionCode'),
      clearable: true
    },
    // 货位类型
    {
      name: 'goodsPositionType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.goodsPositionType'),
      clearable: true,
      data: that.baseGoodsPositionTypeList
    },
    // 容器编码
    {
      name: 'containerArchivesId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerArchivesCode'),
      clearable: true,
      data: that.containerCodeList
    },
    // 库位编码
    {
      name: 'factoryPositionCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryPositionCode'),
      clearable: true
    },
    // 所属库区
    {
      name: 'warehouseAreaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      clearable: true,
      filterable: true,
      data: that.warehouseAreaList
    },
    // 所属设备
    {
      name: 'deviceId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingDevice'),
      filterable: true,
      data: that.deviceList
    },
    {
      name: 'goodsPositionStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.goodsPositionStatus'),
      data: that.freezeFlagList
    },
    {
      name: 'usableFlag',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.usableFlag'),
      data: that.statusFlagList
    }
  ]
  moreQueryData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 货位编码
    {
      prop: 'goodsPositionCode',
      label: that.$t('lang.gles.base.goodsPositionCode'),
      isShow: true,
      sortable: 'custom',
      minWidth: 140
    },
    // 货位类型
    {
      prop: 'goodsPositionType',
      label: that.$t('lang.gles.base.goodsPositionType'),
      isShow: true,
      minWidth: 78,
      formatter(row, column) {
        return getFormatter(that.baseGoodsPositionTypeList, row[column])
      }
    },
    // 容器编码
    {
      prop: 'containerArchivesCode',
      label: `${that.$t('lang.gles.base.containerArchivesCode')}`,
      isShow: true,
      showOverflowTooltip: true,
      minWidth: 140
    },
    // 货位序号
    {
      prop: 'goodsPositionNum',
      label: `${that.$t('lang.gles.base.goodsPositionNum')}`,
      isShow: true,
      minWidth: 78
    },
    // 容器面
    {
      prop: 'containerDirection',
      label: `${that.$t('lang.gles.base.containerDirection')}`,
      isShow: true,
      minWidth: 64,
      formatter(row, column) {
        return getFormatter(that.containerDirectionList, row[column])
      }
    },
    // 行
    {
      prop: 'goodsPositionRow',
      label: `${that.$t('lang.gles.base.row')}`,
      isShow: true,
      width: 60
    },
    // 列
    {
      prop: 'goodsPositionColumn',
      label: `${that.$t('lang.gles.base.column')}`,
      isShow: true,
      width: 60
    },
    // 层
    {
      prop: 'goodsPositionFloor',
      label: `${that.$t('lang.gles.base.floor')}`,
      isShow: true,
      width: 60
    },
    // 层高
    {
      prop: 'floorHigh',
      label: `${that.$t('lang.gles.base.floorHigh')}(cm)`,
      isShow: true,
      minWidth: 84
    },
    // 所属库区
    {
      prop: 'warehouseAreaId',
      label: `${that.$t('lang.gles.base.belongingWarehouseArea')}`,
      isShow: true,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.warehouseAreaList, row[column])
      }
    },
    // 所属设备
    {
      prop: 'deviceName',
      label: `${that.$t('lang.gles.base.belongingDevice')}`,
      isShow: true
    },
    // 入库顺序
    {
      prop: 'inStoreOrder',
      name: 'inStoreOrder',
      label: `${that.$t('lang.gles.base.inStoreOrder')}`,
      component: 'elInput',
      isShow: true,
      minWidth: 170,
      rules: mapVerify(['positiveIntegerLen8'])
    },
    // 出库顺序
    {
      prop: 'outStoreOrder',
      name: 'outStoreOrder',
      label: `${that.$t('lang.gles.base.outStoreOrder')}`,
      component: 'elInput',
      isShow: true,
      minWidth: 170,
      // rules: mapVerify(['positiveIntegerLen8']),
      rules: mapVerify(['positiveIntegerLen8'])
    },
    // 物料上限
    {
      prop: 'materialMaximum',
      label: `${that.$t('lang.gles.base.materialMaximum')}`,
      isShow: true,
      minWidth: 78
    },
    // 容器上限
    {
      prop: 'containerMaximum',
      label: `${that.$t('lang.gles.base.containerMaximum')}`,
      isShow: true,
      minWidth: 78
    },
    // 存放库位
    {
      prop: 'factoryPositionCode',
      label: `${that.$t('lang.gles.base.storageFactoryPosition')}`,
      isShow: true,
      minWidth: 140
    },
    // 货位状态
    {
      prop: 'goodsPositionStatus',
      label: `${that.$t('lang.gles.base.goodsPositionStatus')}`,
      isShow: true,
      minWidth: 78,
      formatter(row, column) {
        return getFormatter(that.freezeFlagList, row[column])
      }
    },
    // 启用状态
    {
      prop: 'usableFlag',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      slotName: 'statusSlot',
      minWidth: 78,
      formatter(row, column) {
        return getFormatter(that.statusFlagList, row[column])
      }
    },
    // 状态变更原因
    {
      prop: 'statusChangeReason',
      label: `${that.$t('lang.gles.base.statusChangeReason')}`,
      isShow: true,
      showOverflowTooltip: false,
      minWidth: 110
    },
    // 货位描述
    {
      prop: 'goodsPositionDescription',
      label: `${that.$t('lang.gles.base.goodsPositionDescription')}`,
      isShow: true,
      showOverflowTooltip: true
    },
    // 创建人
    {
      prop: 'createUser',
      label: `${that.$t('lang.gles.common.createUser')}`,
      isShow: true
    },
    {
      prop: 'createTime',
      label: `${that.$t('lang.gles.common.createTime')}`,
      isShow: true,
      minWidth: 180
    },
    {
      prop: 'updateUser',
      label: `${that.$t('lang.gles.common.updateUser')}`,
      isShow: true
    },
    {
      prop: 'updateTime',
      label: `${that.$t('lang.gles.common.updateTime')}`,
      isShow: true,
      minWidth: 180
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}
