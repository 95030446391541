var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-data-list goods-position-wrapper"},[_c('query-view',{ref:"myQueryView",attrs:{"name":"codeOrName","value":_vm.baseFormModel.codeOrName,"placeholder":_vm.$t('lang.gles.common.pleaseInputCode'),"query-list":_vm.moreQueryData},on:{"update:value":function($event){return _vm.$set(_vm.baseFormModel, "codeOrName", $event)},"baseSearch":_vm.handleBaseSearch,"moreSearch":_vm.handleMoreSearch}}),_c('el-card',{staticClass:"table-card"},[_c('div',{staticClass:"operator-wrapper"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.handleExportTable}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.export'))+" ")]),_c('column-config',{attrs:{"list":_vm.columnConfigList},on:{"update:list":function($event){_vm.columnConfigList=$event}}})],1),_c('table-form',{ref:"goodsPositionEditTable",attrs:{"border":"","table-data":_vm.tableData,"base-operation":_vm.baseOperation,"extend-config":_vm.tableExtendConfig,"table-item":_vm.tableItem,"form-data":_vm.tableItem,"page-data":_vm.pageData},on:{"pageChange":_vm.pageChange,"saveRowItem":_vm.handleSave,"enableItem":_vm.handleChangeUsableStatus,"sort-change":_vm.handleSortChange},scopedSlots:_vm._u([{key:"goodsPositionStatusSlot",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"plan-task-status"},[_c('i',{staticClass:"ui-dot-process",style:({
              '--dotColor': _vm.getGoodsPositionStatusColor(
                row.goodsPositionStatus
              )
            })}),_vm._v(" "+_vm._s(_vm.getDictLabel(_vm.freezeFlagList, row.goodsPositionStatus))+" ")])]}},{key:"statusSlot",fn:function(ref){
            var row = ref.row;
return [_c('span',{staticClass:"plan-task-status"},[_c('i',{staticClass:"ui-dot-process",style:({ '--dotColor': _vm.getUsableFlagColor(row.usableFlag) })}),_vm._v(" "+_vm._s(_vm.getDictLabel(_vm.statusFlagList, String(row.usableFlag)))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }