<template>
  <div class="container-type">
    <keep-alive>
      <component
        :is="currentCom"
        :mode="mode"
        :row-detail.sync="row"
        @updateCom="updateCom"
        @update:row-detail="rowDetail = $event"
      />
    </keep-alive>
  </div>
</template>
<script>
import GoodsPositionList from './components/list.vue'
export default {
  components: {
    GoodsPositionList
  },
  data() {
    return {
      currentCom: 'GoodsPositionList',
      mode: '',
      row: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row = {} }) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
      console.log(this.currentCom, this.mode, this.row)
    }
  }
}
</script>
<style lang="scss" scoped></style>
