<template>
  <div class="basic-data-list goods-position-wrapper">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCode')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleExportTable"
        >
          {{ $t('lang.gles.common.export') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <table-form
        ref="goodsPositionEditTable"
        border
        :table-data="tableData"
        :base-operation="baseOperation"
        :extend-config="tableExtendConfig"
        :table-item="tableItem"
        :form-data="tableItem"
        :page-data="pageData"
        @pageChange="pageChange"
        @saveRowItem="handleSave"
        @enableItem="handleChangeUsableStatus"
        @sort-change="handleSortChange"
      >
        <template #goodsPositionStatusSlot="{ row }">
          <span class="plan-task-status">
            <i
              class="ui-dot-process"
              :style="{
                '--dotColor': getGoodsPositionStatusColor(
                  row.goodsPositionStatus
                )
              }"
            />
            {{ getDictLabel(freezeFlagList, row.goodsPositionStatus) }}
          </span>
        </template>
        <template #statusSlot="{ row }">
          <span class="plan-task-status">
            <i
              class="ui-dot-process"
              :style="{ '--dotColor': getUsableFlagColor(row.usableFlag) }"
            />
            {{ getDictLabel(statusFlagList, String(row.usableFlag)) }}
          </span>
        </template>
      </table-form>
    </el-card>
  </div>
</template>
<script>
import axios from 'axios'
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import TableForm from '@/components/tableForm/editBase.vue'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import Mixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'
import { downloadFn } from '@/utils/utils'

export default {
  name: 'GoodsPositionList',
  components: {
    ColumnConfig,
    TableForm,
    QueryView
  },
  mixins: [Mixins, commonMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        codeOrName: ''
      },
      formModel: {},
      baseOperation: [],
      tableExtendConfig: {
        columnMinWidth: 12 * 8,
        operateWidth: '150px',
        operate: [
          {
            event: 'enableItem',
            getLabel: this.getEnableLabel,
            disabled: this.isEnableOptEnabled,
            loading: this.isItemLoading
          },
          {
            event: '__editRowItem',
            label: this.$t('lang.gles.common.edit'),
            condition({ row }) {
              return !row.$isRowEdit
            }
          },
          {
            event: '__saveRowItem',
            label: this.$t('lang.gles.common.save'),
            condition({ row }) {
              return row.$isRowEdit
            }
          },
          {
            event: '__cancelRowItem',
            label: this.$t('lang.gles.common.cancel'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.strategy.strategy.isSureCancel'),
            condition({ row }) {
              return row.$isRowEdit
            }
          }
        ]
      },
      containerTypeList: [],
      timer: null,
      loading: false,
      currentRow: {},
      cancelToken: null,
      timerCount: 0
    }
  },
  computed: {
    ...mapState('base', [
      'freezeFlagList',
      'statusFlagList',
      'containerDirectionList',
      'baseGoodsPositionTypeList'
    ]),
    moreQueryData() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.doSearch(this.pageData)
    this.initSelectList()
  },
  destoryed() {
    clearInterval(this.timer)
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getDeviceList()
      this.getWarehouseAreaList()
      this.getFactoryPositionList()
      this.getContainerCodeList()
      if (!this.statusFlagList?.length) {
        this.$store.dispatch('base/getStatusFlagList')
      }
      if (!this.containerDirectionList?.length) {
        this.$store.dispatch('base/getContainerDirectionList')
      }
      if (!this.baseGoodsPositionTypeList?.length) {
        this.$store.dispatch('base/getBaseGoodsPositionTypeList')
      }
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getGoodsPositionList')
    },
    /**
     * 查询
     */
    async doSearch(params, api = 'getGoodsPositionListByCodeOrNamePage') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.base(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    getEnableLabel(row) {
      return row.usableFlag === 0
        ? this.$t('lang.gles.common.disable')
        : this.$t('lang.gles.common.enable')
    },
    /**
     * 库位状态 class
     * @param {*} state
     */
    getGoodsPositionStatusColor(state) {
      return state === 0 ? '#00c07b' : '#409eff'
    },
    /**
     * 启用状态 class
     * @param {*} state
     * 启用 绿色
     * 禁用 红色
     * 异常 橙色
     */
    getUsableFlagColor(state) {
      return state === 0 ? '#00c07b' : state === 1 ? '#e72f38' : '#ea9f35'
    },
    /**
     * 启用禁用按钮是否可用
     * 1、状态=启用 且 状态变更原因=空时，DMP可以停用，库位页面可以停用
     * 2、状态=停用 且 状态变更原因=空时，DMP可以启用，库位页面可以启用；
     * 3、状态=停用 且 状态变更原因≠空时，DMP可以启用，库位页面不可以启用（不显示启用按钮）；
     * 4、状态=异常 且 状态变更原因≠空时，DMP可以启用，库位页面不可以启用（不显示启用按钮）；
     * 停用/异常时，只变更基础数据中的状态，不改变库存中数据的状态，且可以查询出库存数据
     */
    isEnableOptEnabled({ row }) {
      return !row.statusChangeReason
    },
    isItemLoading({ row }) {
      return this.currentRow?.id === row?.id && this.loading
    },
    /**
     * 排序变化事件
     */
    handleSortChange({ column, prop, order }) {
      this.tableData.sort((a, b) => {
        return order === 'ascending'
          ? a[prop].localeCompare(b[prop])
          : b[prop].localeCompare(a[prop])
      })
    },
    /**
     * 启用/禁用 更新货位的启用和禁用状态
     * 1、状态为占用的容器，点击禁用，弹窗提示，若确定强制禁用，则禁用
     * 2、启用容器 容器正常，但有库存记录， 点击禁用，弹窗提示，若强制禁用，则禁用
     */
    handleChangeUsableStatus({ row }) {
      if (this.loading) return
      let confirmMsg = this.$t(
        'lang.gles.base.wantEnable/DisableThisGoodsPosition'
      )
      // 原状态是启用，禁用时，如果货位属于冻结状态
      if (row.usableFlag === 0 && row.goodsPositionStatus === 1) {
        confirmMsg = this.$t('lang.gles.base.hasTaskStillDisable')
      }
      if (confirmMsg) {
        this.$confirm(confirmMsg, '', {
          confirmButtonText: this.$t('lang.gles.common.confirm'),
          cancelButtonText: this.$t('lang.gles.common.cancel'),
          type: 'warning'
        }).then(() => {
          this.updateUsableStatus(row)
        })
        return
      }
    },
    async updateUsableStatus(row) {
      this.currentRow = row
      this.loading = true
      const { data, code } = await this.$httpService(
        this.$apiStore.base('updateGoodsPosition'),
        {
          id: row.id,
          usableFlag: row.usableFlag ? 0 : 1,
          isUpdateUsableFlag: true
        }
      )
      if (code) {
        this.loading = false
        this.cancelToken = null
        return
      }
      const { taskId } = data
      if (!taskId) {
        this.$message.success(this.$t('lang.gles.common.operateSuccessfully'))
        row.usableFlag = row.usableFlag ? 0 : 1
        this.loading = false
        this.cancelToken = null
        return
      }
      this.timer = setInterval(() => {
        this.timerCount++
        this.queryDmpCallbackRecord(row, taskId)
      }, 3000)
    },
    async queryDmpCallbackRecord(row, taskId) {
      if (this.cancelToken) return
      this.cancelToken = axios.CancelToken
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryDmpCallbackRecord'),
        {
          taskId
        }
      )
      this.cancelToken = null
      if (code) {
        return
      }
      if (Number(data.execResult) !== 10) {
        this.loading = false
        if (Number(data.execResult) === 0) {
          this.$message.success(this.$t('lang.gles.common.operateSuccessfully'))
          row.usableFlag = row.usableFlag ? 0 : 1
        } else {
          this.$message.error(this.$t('lang.gles.common.operateFailed'))
        }
        clearInterval(this.timer)
        return
      }
      if (this.timerCount === 5) {
        clearInterval(this.timer)
        this.$message.warning(this.$t('lang.gles.common.timeOutTryAgainLater'))
        this.loading = false
        this.cancelToken = null
        this.timerCount = 0
        return
      }
    },
    /**
     * 导出货位
     */
    handleExportTable() {
      const params = this.$refs?.myQueryView.getHistorySearchData()
      const url = this.$apiStore.base('exportGoodsPosition', this.pageData)?.url
      downloadFn(url, params)
    },
    async handleSave({ row, $index }) {
      const { code } = await this.$httpService(
        this.$apiStore.base('updateGoodsPosition'),
        row
      )
      if (code) {
        this.$refs?.myQueryView?.histSearch()
        return
      }
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
    }
  }
}
</script>
<style lang="scss">
.goods-position-wrapper {
  .el-input--suffix .el-input__inner {
    padding-right: 0;
  }
}
</style>
